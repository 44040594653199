import "./bootstrap";
import Rellax from "rellax";
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import "./theme-handler";

Alpine.plugin(persist);
window.Alpine = Alpine;

Alpine.start();

var rellax = new Rellax(".rellax");

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

window.slideToCTA = () => {
    gsap.to(window, { duration: 2.5, scrollTo: { y: "#ctaContainer" }, ease: "power3.inOut" });
};

window.slideToTop = () => {
    gsap.to(window, { duration: 1.5, scrollTo: { y: "#mainNav" }, ease: "power3.inOut" });
};

window.getHeight = (element) => {
    return document.querySelector(element).getBoundingClientRect().height.toFixed(0);
};

window.getWidth = (element) => {
    //console.log(`width of ${element}`, document.querySelector(element).getBoundingClientRect().width.toFixed(0));
    return document.querySelector(element).scrollWidth.toFixed(0);
};

window.redirectTo = (url) => {
    window.location.href = url;
};